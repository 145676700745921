export default {
    created() {
    },
    computed: {
        env() {
            return this.$route.params.env;
        },
    },
    methods: {
        debounceValuesChange(changes) {
            this.$nextTick(() => {
                for (let changedKey in changes) {
                    this.values[changedKey] = changes[changedKey];
                }
            });
        }
    },
}
