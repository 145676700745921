var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("store-settings-header", {
        attrs: {
          titleBarActions: _vm.titleBarActions,
          "hide-env-selector": _vm.editing,
        },
      }),
      _c("SettingsForm", {
        attrs: {
          groups: _vm.settingsGroups,
          values: _vm.values,
          rules: _vm.rules,
        },
        on: { change: _vm.formChanged },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }