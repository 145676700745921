exports.FormRules = {
    IntegerNumber: function (val) {
        if (isNaN(val)) return 'Must be a number.'
        return true
    },
    NumberBetween: function (min, max) {
        return (val) => {
            if (this.IntegerNumber(val) !== true) return this.IntegerNumber(val)
            if (parseInt(val) <= min) {
                return `Number must be ${min} or more.`
            } else if (parseInt(val) >= max) {
                return `Number must be ${max} or less.`
            }
            return true
        }
    },
    NumberGreaterThan: function(min) {
        return (val) => {
            if (this.IntegerNumber(val) !== true) return this.IntegerNumber(val)
            if (parseInt(val) < min) {
                return `Number must be ${min} or more.`
            }
            return true
        }
    }
} 