<template>
  <div>
    <store-settings-header :titleBarActions="titleBarActions" :hide-env-selector="editing" />
    <!-- <v-container class="align">
      <v-row  class="settings-container"> -->
        <SettingsForm :groups="settingsGroups" :values="values" :rules="rules" @change="formChanged" />
      <!-- </v-row>
    </v-container> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SettingsForm from "../components/storesettings/settings_form.vue";
import storeSettingsHeader from "../components/store_settings_header.vue";
import StoreSettingsMixin from "../mixins/store_settings.js";
import { FormRules } from "../helpers/settings.js";

import clone from "clone";

export default {
    name: "StoreSettingsTableTop",
    mixins: [StoreSettingsMixin],
    components: {
        storeSettingsHeader,
        SettingsForm
    },
    data() {
        return {
            editing: false,
            values: {},
            rules: {
                tippingAutoClosePercent: [FormRules.NumberBetween(0, 100)],
                autoCloseTimeMinutes: [FormRules.NumberGreaterThan(0)],
                tabMaximum: [FormRules.NumberGreaterThan(0)]
            }
        };
    },
    computed: {
        titleBarActions() {
            if (!this.editing) {
                return [
                    {
                        type: "edit",
                        display: "Edit",
                        run: () => {
                            this.editing = true;
                        }
                    }
                ];
            } else {
                return [
                    {
                        type: "cancel",
                        display: "Cancel",
                        run: this.cancelEditing
                    },
                    {
                        type: "proceed-green",
                        display: "Save",
                        run: () => {
                            this.saveSettings();
                        }
                    }
                ];
            }
        },
        settingsGroups() {
            if (this.values === undefined) return []
            let enabledDisabledLabels = {
                true: "Enabled",
                false: "Disabled"
            };

            let tabletopGroup = {
                name: "Tabletop Settings",
                disabled: !this.editing,
                expanded: true,
                sections: [
                    {
                        fields: [
                            {
                                key: "tableTop",
                                display: "Enable Order at Table",
                                type: "boolean"
                            }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: "autoReceiptEnabled",
                                display: "Auto Receipt",
                                type: "boolean",
                                labels: enabledDisabledLabels
                            },
                            {
                                key: "discountEnabled",
                                display: "Discount",
                                type: "boolean",
                                labels: enabledDisabledLabels
                            },
                            {
                                key: "textReceiptEnabled",
                                display: "Text Receipt",
                                type: "boolean",
                                labels: enabledDisabledLabels
                            }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: "usesTables",
                                display: "POS Uses Tables",
                                type: "boolean"
                            },
                            {
                                key: "usesTipping",
                                display: "POS Uses Tipping",
                                type: "boolean"
                            },
                            {
                                key: "warnOnExistingTicketsOnTable",
                                display: "Warn when tickets already exist on table",
                                type: "boolean"
                            },
                            {
                                key: "requireAssignedEmployee",
                                display: "Require assigned employee",
                                type: "boolean"
                            },
                            {
                                key: "staticUpsellAddon",
                                display: "Static Upsell/Addon",
                                type: "boolean"
                            }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: "tippingAutoAddOnClose",
                                display: "Add Tip on Auto Close",
                                type: "boolean"
                            },
                            {
                                key: "tippingAtCloseOnly",
                                display: "Tipping at close only",
                                type: "boolean"
                            },
                            {
                                key: "tippingAutoClosePercent",
                                display: "Auto Close Tip Percent",
                                type: "singleline"
                            },
                            {
                                key: "autoCloseTimeMinutes",
                                display: "Auto Close Time (minutes)",
                                type: "singleline"
                            },
                            {
                                key: "tabMaximum",
                                display: "Maxiumum Tab Amount",
                                type: "singleline"
                            }
                        ]
                    }
                ]
            };

            return [tabletopGroup];
        },
        tabletopSettings() {
            if (this.env == "stage") return this.tabletopSettingsStage;
            if (this.env == "prod") return this.tabletopSettingsProd;
        },
        ...mapGetters("SettingsStore", [
            "tabletopSettingsStage",
            "tabletopSettingsProd"
        ]),
        ...mapGetters(["store", "pos"])
    },
    methods: {
        cancelEditing() {
            this.editing = false;
            this.values = clone(this.tabletopSettings) ;
        },
        formChanged(newValue) {
            var changes = {};

            this.debounceValuesChange(changes);
        },
        resolvePageTitle() {
            if (Object.keys(this.store).length) {
                document.title = `${this.store.storeName} - Tabletop`;
            }
        },
        saveSettings() {
            let storeWaypointID = this.$route.params.store;
            let env = this.env;

            this.saveTableTopSettings({
                storeWaypointID,
                env,
                settings: clone(this.values)
            }).then(() => {
                this.values = clone(this.tabletopSettings);
                this.editing = false;
            });
        },
        ...mapActions("SettingsStore", [
            "getTableTopSettings",
            "saveTableTopSettings"
        ])
    },
    watch: {
        store() {
            this.resolvePageTitle();
        }
    },
    created() {
        this.getTableTopSettings({
            storeWaypointID: this.$route.params.store,
            env: this.env
        }).then(() => {
            this.values = clone(this.tabletopSettings);
        });
    }
};
</script>

<style lang="scss" scoped>
.settings-container {
  max-width: 75%;
}
</style>
